import { Contract } from '@ethersproject/contracts'
import BlowFish_ERC1155_ABI from 'contracts/BlowFish-ERC1155.json'
import DividendDistributor_ABI from 'contracts/DividendDistributor.json'
import IERC20Metadata_ABI from 'contracts/IERC20Metadata.json'

export const Networks = {
  ETHMainNet: 1,
  SepoliaTestNet: 11155111
}

export const CONTRACTS_BY_NETWORK = {
  // [Networks.ETHMainNet]: {
  //   BlowFish: {
  //     address: '0xf05De5372F20BABEE71516815108ef5D786Fcf8C',
  //     abi: BlowFish_ERC1155_ABI,
  //   },
  //   USDT: {
  //     address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  //     abi: IERC20Metadata_ABI,
  //   },
  //   USDC: {
  //     address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  //     abi: IERC20Metadata_ABI,
  //   },
  //   DividendDistributor: {
  //     address: '0x227564Ae28968F3bc97B962B43b9115743489107',
  //     abi: DividendDistributor_ABI,
  //   }
  // },

  [Networks.ETHMainNet]: {
    BlowFish: {
      address: '0x3FdD0592237e4d8f12808718D210370344dCBf2b',
      abi: BlowFish_ERC1155_ABI,
    },
    USDT: {
      address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      abi: IERC20Metadata_ABI,
    },
    USDC: {
      address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      abi: IERC20Metadata_ABI,
    },
    DividendDistributor: {
      address: '0x3C9FC8c9B5e066D41473e7377DD53072BD86935a',
      abi: DividendDistributor_ABI,
    }
  },

  // [Networks.SepoliaTestNet]: {
  //   BlowFish: {
  //     address: '0xA0Ce5fb6710E70Aa000f236634E645Da3f707fCc',
  //     abi: BlowFish_ERC1155_ABI,
  //   },
  //   USDT: {
  //     address: '0x7d50bfabc3E42959301a3961C79847780eB517fc',
  //     abi: IERC20Metadata_ABI,
  //   },
  //   USDC: {
  //     address: '0x9FF7b6089A17643624858450D873b5af8FB423C4',
  //     abi: IERC20Metadata_ABI,
  //   },
  //   DividendDistributor: {
  //     address: '0x2A58b9Ab772967F35D15686c6eCA19b4b0cbF6bD',
  //     abi: DividendDistributor_ABI,
  //   }
  // }

  [Networks.SepoliaTestNet]: {
    BlowFish: {
      address: '0xf05De5372F20BABEE71516815108ef5D786Fcf8C',
      abi: BlowFish_ERC1155_ABI,
    },
    USDT: {
      address: '0x7d50bfabc3E42959301a3961C79847780eB517fc',
      abi: IERC20Metadata_ABI,
    },
    USDC: {
      address: '0x9FF7b6089A17643624858450D873b5af8FB423C4',
      abi: IERC20Metadata_ABI,
    },
    DividendDistributor: {
      address: '0x227564Ae28968F3bc97B962B43b9115743489107',
      abi: DividendDistributor_ABI,
    }
  }
}

export const currentNetwork = process.env.REACT_APP_NETWORK_ID;
export const baseApiUrl = process.env.REACT_APP_API_URL;
export const wertPartnerId = process.env.REACT_APP_WERT_PARTNER_ID;
export const wertPrivateKey = process.env.REACT_APP_WERT_PRIVATE_KEY;


export function getContractInfo(name, chainId = null) {
  if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export const putCommas = (value) => {
  try {

    if (value >= 0) {
      const cn1 = value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
      // const cn1 = value.toLocaleString('en-US');
      return cn1;
    } else {
      return '';
    }
  } catch (err) {
    return value
  }
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + '...' + endStr;
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + '...' + endStr;
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export function getContractObjWithAddress(name, chainId, provider, contractAddress) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(contractAddress, info.abi, provider);
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + '...' + str.slice(-4) : str
