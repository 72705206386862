import Loader from "components/loader/Loader";
import "./loading.scss";
type PropsType = {
  isLoading?: boolean;
};

export default function LoadingPage({ isLoading }: PropsType) {
  return (
    <div
      className={`${isLoading ? "loading_page1 activeLoading" : "loading_page1"}`}
    >
      <Loader/>
    </div>
  );
}
