import Loading from 'components/loading/Loading';
import Menu from 'components/Layout/menu/Menu';
import Topbar from 'components/Layout/topbar/Topbar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import Home from 'components/sections/home/Home';
import Faq from 'components/sections/faq/Faq';
import Footer from 'components/Layout/footer/Footer';
import Solidproof from 'components/sections/Solidproof/Solidproof';
import BuySale from 'components/sections/BuySale/BuySale';
import Team from 'components/sections/Team/Team';
import Partnerships from 'components/sections/Partnerships/Partnerships';
import RoadMap from 'components/sections/roadmap/RoadMap';
import RewardsProgram from 'components/sections/RewardsProgram/RewardsProgram';
export default function HomePage( ) {
    const [isLoading, setIsLoading] = useState(true);

    const [isLoading1, setIsLoading1] = useState(true);
    const [isLoading2, setIsLoading2] = useState(true);
    const [isLoading3, setIsLoading3] = useState(true);
    const [isLoading4, setIsLoading4] = useState(true);
    const [isLoading5, setIsLoading5] = useState(true);
    const [isLoading6, setIsLoading6] = useState(true);
    const [isLoading7, setIsLoading7] = useState(true);

    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 640px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 640px) and (orientation:landscape)",});
    useEffect(() => {
        if (isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5 || isLoading6 || isLoading7) {
            setIsLoading(true)
        }
        else{
            setIsLoading(false)
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
        
       
    }, [isLoading1, isLoading2, isLoading3, isLoading4, isLoading5, isLoading6, isLoading7, isTabletOrMobile, isLandOrMobile]);
    AOS.init();
    window.onload = ()=> {
        setIsLoading1(false)
        setIsLoading2(false)
        setIsLoading3(false)
        setIsLoading4(false)
        setIsLoading5(false)
        setIsLoading6(false)
        setIsLoading7(false)

        setTimeout(() => {
            AOS.init({
                // Global settings:
                disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
                startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
                initClassName: 'aos-init', // class applied after initialization
                animatedClassName: 'aos-animate', // class applied on animation
                useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
                disableMutationObserver: false, // disables automatic mutations' detections (advanced)
                debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
                throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
                
                // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
                offset: 120, // offset (in px) from the original trigger point
                delay: 0, // values from 0 to 3000, with step 50ms
                duration: 800, // values from 0 to 3000, with step 50ms
                easing: 'ease', // default easing for AOS animations
                once: false, // whether animation should happen only once - while scrolling down
                mirror: false, // whether elements should animate out while scrolling past them
                anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
                
            });
        }, 500);

    };
    return (
        <>
            
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Loading isLoading={isLoading} />
            <div className="sections" style={{opacity : isLoading ? 0: 1, height : isLoading ? 0: 'auto'}}>
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
                <Home  setIsLoading = {setIsLoading1}/>
                <BuySale setIsLoading = {setIsLoading2}/>
                <RewardsProgram setIsLoading={setIsLoading7}/>
                <Solidproof setIsLoading={setIsLoading3}/>
                <Team setIsLoading = {setIsLoading4}/>
                <Partnerships setIsLoading = {setIsLoading5}/>
                <RoadMap setIsLoading = {setIsLoading6}/>
                <Faq />
                <Footer/>
            </div>
            <img src="/assets/images/team_bg_01.jpg" alt="" className="main_bg"/>
        </>
    )
}
