import "@ethersproject/shims"
import { currentNetwork, getContractObj } from ".";
import { PresaleDetails, UserBalance, DistributeDetails } from "./typs";
import { BigNumber, ethers } from "ethers";
import { mainnet } from 'wagmi/chains'
import toast from "react-hot-toast";
import WhiteListMap from "./WhiteListMap.json";

// import Airtable from "airtable";

// var base = new Airtable({ apiKey: 'keyHy7oSJdm2Ap399' }).base('apphUD3xwayXIBETM');

// export function apiAddWalletAddress(address: string) {
//     base('TradeHub ICO Wallets').create([
//         {
//             "fields": {
//                 "Address": address
//             }
//         }
//     ], function (err, records) {
//         if (err) {
//             console.error(err);
//             return;
//         }
//         records.forEach(function (record) {
//             if (!localStorage.getItem(`TradeHub_${address.toLowerCase()}`)) {
//                 localStorage.setItem(`TradeHub_${address.toLowerCase()}`, record.getId());
//             }
//         });
//     });
// }

export async function getUserBalance(account) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(mainnet.rpcUrls.public.http[0]);
    const USDTContract = getContractObj('USDT', currentNetwork, jsonProvider);
    const USDCContract = getContractObj('USDC', currentNetwork, jsonProvider);
    try {
        const [
            ETHBalance,
            USDTBalance,
            USDCBalance,
        ] = await Promise.all([
            account ? jsonProvider.getBalance(account) : BigNumber.from(0),
            account ? USDTContract.balanceOf(account) : BigNumber.from(0),
            account ? USDCContract.balanceOf(account) : BigNumber.from(0),
        ]);

        const userBalance: UserBalance = {
            ETHBalance: parseFloat(ethers.utils.formatEther(ETHBalance)),
            USDTBalance: parseFloat(ethers.utils.formatUnits(USDTBalance, 6)),
            USDCBalance: parseFloat(ethers.utils.formatUnits(USDCBalance, 6)),
        }

        return userBalance;
    } catch (e) {
        console.log(e);
        return null;
    }
}

/********************************************* ICO For V2 *********************************************/

export async function getETHPrice() {
    const data = await fetch("https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD");
    const jsonData = await data.json();
    return jsonData.USD;
}


export async function scGetPresaleDetails() {
    const jsonProvider = new ethers.providers.JsonRpcProvider(mainnet.rpcUrls.public.http[0]);
    const BlowFishContract = getContractObj('BlowFish', currentNetwork, jsonProvider);
    
    try {
        const [
            saleStep,
            membershipTokenPriceInUSDT,
            membershipTokenSoldCount,
            membershipTokenWhitelistSaleLimit,
            membershipTokenPresaleLimit,
            membershipTokenTotalLimit,
            priceETHInUSD,
        ] = await Promise.all([
            BlowFishContract.saleStep(),
            BlowFishContract.membershipTokenPriceInUSDT(),
            BlowFishContract.membershipTokenSoldCount(),
            BlowFishContract.membershipTokenWhitelistSaleLimit(),
            BlowFishContract.membershipTokenPresaleLimit(),
            BlowFishContract.membershipTokenTotalLimit(),
            BlowFishContract.getETH_in_USD(),
        ]);

        const presaleDetails: PresaleDetails = {
            saleStep: saleStep.toNumber(),
            membershipTokenPriceInUSDT: parseFloat(ethers.utils.formatUnits(membershipTokenPriceInUSDT, 6)),
            membershipTokenSoldCount: membershipTokenSoldCount.toNumber(),
            membershipTokenWhitelistSaleLimit: membershipTokenWhitelistSaleLimit.toNumber(),
            membershipTokenPresaleLimit: membershipTokenPresaleLimit.toNumber(),
            membershipTokenTotalLimit: membershipTokenTotalLimit.toNumber(),
            priceETHInUSD: parseFloat(ethers.utils.formatUnits(priceETHInUSD, 18))
        }

        return presaleDetails;
    } catch (e) {
        console.log(e);
        return null;
    }
}


export async function scGetDistributeDetails(account) {
    const jsonProvider = new ethers.providers.JsonRpcProvider(mainnet.rpcUrls.public.http[0]);
    const BlowFishContract = getContractObj('BlowFish', currentNetwork, jsonProvider);
    const DividendDistributorContract = getContractObj('DividendDistributor', currentNetwork, jsonProvider);
    
    try {
        const [
            membershipMintedCount,
            shareDetails,
            rewardsAmount
        ] = await Promise.all([
            account?BlowFishContract.mintedCount(account):BigNumber.from(0),
            account?DividendDistributorContract.shares(account):null,
            account?DividendDistributorContract.getUnclaimedRewards(account):BigNumber.from(0),
        ]);

        const distributeDetails: DistributeDetails = {
            membershipMintedCount: membershipMintedCount.toNumber(),
            membershipTokenHoldingAmount: shareDetails? shareDetails.amount.toNumber(): 0,
            rewardsAmount: parseFloat(ethers.utils.formatEther(rewardsAmount)),
            claimedRewardsAmount: shareDetails?parseFloat(ethers.utils.formatEther(shareDetails.rewardClaimed)):0.0
        }

        return distributeDetails;
    } catch (e) {
        console.log(e);
        return null;
    }
}

export async function scBuyWithUSDT(chainId, provider, account, amount, saleStep) {
    const BlowFishContract = getContractObj('BlowFish', chainId, provider);
    const USDTContract = getContractObj('USDT', chainId, provider);
    try {
        if (parseFloat(amount) < 1000) {
            toast.error("The amount of USDT must be higher than 1000 USDT!");
            return false;
        }
        
        var big_index: BigNumber = BigNumber.from(0);
        var big_amount: BigNumber = BigNumber.from(0);
        var proof = [];

        if (saleStep === 1) {
            const index = WhiteListMap.claims[account]?.index;
            const amount = WhiteListMap.claims[account]?.amount;
            proof = WhiteListMap.claims[account]?.proof;

            if (index === undefined || amount === undefined) {
                toast.error("You are not registered to whitelist.");
                return false;
            }

            big_index = BigNumber.from(index);
            big_amount = BigNumber.from(amount);
        }

        const etherAmount: BigNumber = ethers.utils.parseUnits(amount.toString(), 6);
        const allowancedAmount: BigNumber = await USDTContract.allowance(account, BlowFishContract.address);
        if (allowancedAmount.lt(etherAmount)) {
            const tx = await USDTContract.approve(BlowFishContract.address, etherAmount);
            await tx.wait(1);
        }

        const tx = await BlowFishContract.mintMembership_with_USDT(etherAmount, big_index, big_amount, proof);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scBuyWithUSDC(chainId, provider, account, amount, saleStep) {
    const BlowFishContract = getContractObj('BlowFish', chainId, provider);
    const USDCContract = getContractObj('USDC', chainId, provider);
    try {
        if (parseFloat(amount) < 1000) {
            toast.error("The amount of USDC must be higher than 1000 USDC!");
            return false;
        }

        var big_index: BigNumber = BigNumber.from(0);
        var big_amount: BigNumber = BigNumber.from(0);
        var proof = [];

        if (saleStep === 1) {
            const index = WhiteListMap.claims[account]?.index;
            const amount = WhiteListMap.claims[account]?.amount;
            proof = WhiteListMap.claims[account]?.proof;

            if (index === undefined || amount === undefined) {
                toast.error("You are not registered to whitelist.");
                return false;
            }

            big_index = BigNumber.from(index);
            big_amount = BigNumber.from(amount);
        }
        
        const etherAmount: BigNumber = ethers.utils.parseUnits(amount.toString(), 6);
        const allowancedAmount: BigNumber = await USDCContract.allowance(account, BlowFishContract.address);
        if (allowancedAmount.lt(etherAmount)) {
            const tx = await USDCContract.approve(BlowFishContract.address, etherAmount);
            await tx.wait(1);
        }

        const tx = await BlowFishContract.mintMembership_with_USDC(etherAmount, big_index, big_amount, proof);
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scBuyWithETH(chainId, provider, account, amount, saleStep) {
    const BlowFishContract = getContractObj('BlowFish', chainId, provider);
    try {
        const ethPrice = await getETHPrice();
        if (parseFloat(amount) * parseFloat(ethPrice.toString()) < 1000) {
            toast.error("The amount of ETH must be higher than 1000 USD!");
            return false;
        }

        var big_index: BigNumber = BigNumber.from(0);
        var big_amount: BigNumber = BigNumber.from(0);
        var proof = [];

        if (saleStep === 1) {
            const index = WhiteListMap.claims[account]?.index;
            const amount = WhiteListMap.claims[account]?.amount;
            proof = WhiteListMap.claims[account]?.proof;

            if (index === undefined || amount === undefined) {
                toast.error("You are not registered to whitelist.");
                return false;
            }

            big_index = BigNumber.from(index);
            big_amount = BigNumber.from(amount);
        }


        const etherAmount: BigNumber = ethers.utils.parseEther(amount.toString());

        const tx = await BlowFishContract.mintMembership_with_ETH(big_index, big_amount, proof, {
            value: etherAmount
        });
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function scClaimRewards(chainId, provider) {
    const DividendDistributorContract = getContractObj('DividendDistributor', chainId, provider);
    try {
        const tx = await DividendDistributorContract.claimRewards();
        await tx.wait(1);

        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
}