import { useEffect, useState } from "react";
import "./rewardsProgram.scss"

type PropsType = {
    setIsLoading?: (flag: boolean) => void;
};


export default function RewardsProgram({ setIsLoading }: PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 4){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);


    return (
        <div className="rewardsProgram" id="">
            <div className="content">
                <div className="title">
                    <h1>Private Offering Rewards Program</h1>
                </div>
                <div className="wrapper" >
                    <div className="card_div">
                        <img src="/assets/images/Diamond award.png" alt="" onLoad={onLoad} />
                        <div className="text">
                            <h3>Diamond Rewards $50K + </h3>
                            <p>10% Share Bonus (1 share per 10k)</p>
                            <p>5+ Bottle Extra Añejo (1 bottle per 10k)</p>
                            <p>45+ Bottle Credit (1 per share)</p>
                            <p>Blanco/Reposado/Añejo-Credit</p>
                            <p>Free Shipping (6 per Case) ok to mix/match</p>
                        </div>
                    </div>
                    <div className="card_div">
                        <img src="/assets/images/platinum award.png" alt=""  onLoad={onLoad}/>
                        <div className="text">
                            <h3>Platinum Rewards $25K + </h3>
                            <p>10% Share Bonus (1 share per 10k)</p>
                            <p>2+ Bottle Extra Añejo (1 bottle per 10k)</p>
                            <p>23+ Bottle Credit (1 per share)</p>
                            <p>Blanco/Reposado/Añejo-Credit</p>
                            <p>Free Shipping (6 per Case) ok to mix/match</p>
                        </div>
                    </div>
                    <div className="card_div">
                        <img src="/assets/images/Gold Award.png" alt=""  onLoad={onLoad}/>
                        <div className="text">
                            <h3>Gold Rewards $10K +  </h3>
                            <p>10% Share Bonus (1 share per 10k)</p>
                            <p>1+ Bottle Extra Añejo (1 bottle per 10k)</p>
                            <p>9+ Bottle Credit (1 per share)</p>
                            <p>Blanco/Reposado/Añejo-Credit</p>
                            <p>Free Shipping (6 per Case) ok to mix/match</p>
                        </div>
                    </div>
                    <div className="card_div">
                        <img src="/assets/images/silveraward.png" alt=""  onLoad={onLoad}/>
                        <div className="text">
                            <h3>Silver Rewards $5K + </h3>
                            <p>5+ Bottle Credit (1 per share)</p>
                            <p>Blanco/Reposado/Añejo-Credit</p>
                            <p>Free Shipping ok to mix/match</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
