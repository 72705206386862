import "./menu.scss"
import { HashLink } from 'react-router-hash-link'
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
type MenuType = {
    menuOpen: boolean;
    setMenuOpen(flag: boolean): void;
};

export default function Menu({ menuOpen, setMenuOpen }: MenuType) {
    const [navId, setNavId] = useState('')
    const search = useLocation();
    const isTabletOrMobile = useMediaQuery({ query: "screen and (max-width: 640px) and (orientation:portrait)", });

    useEffect(() => {
        const label = search.hash.replace('#', '')
        setNavId(label)
    }, [setNavId, search]);
    return (
        <div className={"sidebar " + (menuOpen && "active")}>
            <div className="content">
                <ul>
                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem1 ${menuOpen ? "active" : ""} ${navId === 'buy_sale' ? 'selected' : ''}`}>
                        <HashLink to="/#buy_sale" smooth><i className="fas fa-caret-right"></i> Buy/Sale</HashLink>
                    </li>


                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem2 ${menuOpen ? "active" : ""} ${navId === 'solidproof' ? 'selected' : ''}`}>
                        <HashLink to="/#solidproof" smooth style={{ letterSpacing: -1 }}><i className="fas fa-caret-right"></i> SolidProof Audit</HashLink>
                    </li>


                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem3 ${menuOpen ? "active" : ""} ${navId === 'team' ? 'selected' : ''}`}>
                        <HashLink to="/#team" smooth><i className="fas fa-caret-right"></i> Team</HashLink>
                    </li>
                    
                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem4 ${menuOpen ? "active" : ""} ${navId === 'roadmap' ? 'selected' : ''}`}>
                        <HashLink to="/#roadmap" smooth>
                            <i className="fas fa-caret-right"></i>Roadmap
                        </HashLink>
                    </li>
                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem5 ${menuOpen ? "active" : ""} ${navId === '' ? 'selected' : ''}`}>
                        <a href="https://docsend.com/view/zptnu2teuqyep923" target="_blank" rel="noreferrer">
                            <i className="fas fa-caret-right" /> White paper
                        </a>
                    </li>
                    <li onClick={() => isTabletOrMobile && setMenuOpen(false)} className={`menuItem6 ${menuOpen ? "active" : ""} ${navId === '' ? 'selected' : ''}`}>
                        <a href="https://docsend.com/view/dk4inxihry6gmu5q" target="_blank" rel="noreferrer">
                            <i className="fas fa-caret-right" /> Pitchdeck
                        </a>
                    </li>
                </ul>
                
            </div>
        </div>
    )
}

