import './counter.scss';

interface PropsType{
  setValue?:any
  value?:number
}
export default function Counter({setValue, value}:PropsType) {

    const decreaseHandle = () => {
      if (value > 0) {
        setValue(value - 1)
      }
    }
    const increaseHandle = () => {
      if (value < 99999) {
        setValue(value + 1);
      }
    }
    const onChnageValue = (e:any) => {
      setValue(parseInt(e.target.value))
    }

    return (
      <>
        <div className="counter">
          <input type="number" value = {value} onChange={onChnageValue} />
          <div className="btn_div">
            

            <button
              className="btn inc"
              onClick={increaseHandle}
            >
              <i className="fas fa-angle-up"></i>
            </button>

            <button
              className="btn dec"
              onClick={decreaseHandle}
            >
              <i className="fas fa-angle-down"></i>
            </button>

          </div>
        </div>
      </>
    )
}

