import "./loading.scss";
type PropsType = {
  isLoading?: boolean;
};

export default function Loading({ isLoading }: PropsType) {
  return (
    <div
      className={`${isLoading ? "loading_page activeLoading" : "loading_page"}`}
    >
      <img className="logoIcon" alt="" src="assets/logo.png" />
      {/* <div className="loading_div">
        <div className="icon">
        <svg width="163.99809000000002" height="187.99928" viewBox="0 0 164 188" fill="none" xmlns="http://www.w3.org/2000/svg" opacity="1"><g ><path d="M154.717 136.831C131.079 176.993 79.3593 190.387 39.1981 166.75M9.27994 51.231C32.9178 11.0698 84.6373 -2.325 124.798 21.3129" stroke="#ff660055"></path></g><defs></defs></svg>
        </div>
        <div className="circle1"></div>
        <div className="circle2"></div>
        <h2>LOADING</h2>
      </div> */}
      
      
    </div>
  );
}
