import './modal.scss'
import Bounce  from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
interface Props {
    showModal: boolean,
    setShowModal?: any
    children : any

}
const ConnectModal: React.FC<Props> = ({
    showModal,
    setShowModal,
    children
}) => {


    const [isStart, setIsStart] = useState(false)
    useEffect(() => {
        if(showModal)
        {
            setTimeout(() => {
                setIsStart(true)
            }, 100)
        }
        
    }, [setIsStart,  showModal]);
    const onClose = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowModal(false);
        }, 800)
    }
    return (
        <div className={showModal === true ? "modal active" : "modal"}>
            <Bounce opposite when={isStart}>
            <div className="model_content">
                <div className="modal_header">
                    <h1 className="modal_title">Purchase From Card</h1>
                    <button className="connectModalCloseButton" onClick={onClose}><i className="fas fa-times"></i></button>
                </div>
                <div className="modal_wrapper">
                    {children}
                </div>
            </div>
            </Bounce>

        </div>
    )
}
export default ConnectModal;