import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link'
import './footer.scss'


type LoadingType = {
    setIsLoading?(flag: boolean): void;
};

export default function Footer({ setIsLoading }: LoadingType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 5) {
            setIsLoading && setIsLoading(false)
        }
    }, [setIsLoading, imgCount]);
    return (
        <div className="footer">
            <div className="footerContent" style={{backgroundImage : `url("/assets/images/footer-background.gif")`}}>
                <HashLink className = 'logo' to={'#home'} smooth >
                    <img src={'/assets/image-2.png'} alt=""  onLoad = {onLoad}/>
                </HashLink>
                <div className="wrapper">

                    <div className="right">
                        <div className="col">
                            <h3>CONTACT</h3>
                                <p>San Diego California</p>
                                <a href="mailto:Adam@blowfishtequila.com  " target="_blank"rel="noreferrer">
                                Adam@blowfishtequila.com 
                                </a> 
                        </div>
                        <div className="col">
                            <h3>Legal</h3>
                            <HashLink to={'/privacy'} smooth >Privacy Policy</HashLink>
                            <a href="/assets/Blowfish_Tequila_Disclosures_1.pdf" target="_blank"rel="noreferrer">Disclosures</a>
                        </div>
                        
                        <div className="col">
                            
                            <div className="socialLinks">
                                
                                <a href="https://www.facebook.com/profile.php?id=61554290413112" target="_blank"rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a> 
                                <a href="https://www.instagram.com/blowfishtequila/" target="_blank"rel="noreferrer">
                                <i className="fab fa-instagram-square"></i>
                                </a> 
                                <a href="https://twitter.com/blowfishtequila" target="_blank"rel="noreferrer">
                                    <i className="fab fa-twitter"></i>
                                </a> 
                                <a href="https://t.me/+EP8EAgiujA9jYjAx" target="_blank"rel="noreferrer">
                                    <i className="fab fa-telegram"></i>
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="foot">
                    
                    <span>© 2023 BLOWFISH TEQUILA. ALL RIGHTS RESERVED</span>
                </div>
            </div>
        </div>
    )
}
 