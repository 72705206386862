import ReactDOM from 'react-dom';
import App from './App';

import Web3RainbowKitProvider from 'hooks/Web3RainbowKitProvider';
import ActiveWeb3Provider from 'hooks/useActiveWeb3';
import { LoadingProvider } from 'contexts/LoadingProvider';

ReactDOM.render(
  <Web3RainbowKitProvider>
    <LoadingProvider>
      <ActiveWeb3Provider>
        <App />
      </ActiveWeb3Provider>
    </LoadingProvider>
  </Web3RainbowKitProvider>,
  document.getElementById('root')
);