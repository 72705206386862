import { useEffect, useState } from 'react';
import './partnerships.scss'

type PropsType = {
    setIsLoading?: (flag: boolean) => void;

};

const img_list = [
    // "/assets/images/partnership.png",
    "/assets/images/solid-proof.png",
    // "/assets/images/certik1.svg",
    // "/assets/images/consulting-partners.svg",
    // "/assets/images/luna-capital.png",
    // "/assets/images/accubits.svg",
    // "/assets/images/azuro.svg",
    // "/assets/images/binance.svg",
    // "/assets/images/bitmart.svg",
    "/assets/images/1.png",
    "/assets/images/2.png",
    "/assets/images/3.png",
    "/assets/images/4.png",
    "/assets/images/5.png",
    "/assets/images/6.png",
    "/assets/images/7.png",
    "/assets/images/8.png",
    "/assets/images/9.png",
    "/assets/images/10.png",
    

]
export default function Partnerships({ setIsLoading }: PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if (imgCount >= 38) {
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);


    return (
        <div className="partnerships" id="partnerships">
            <div className="content">
                <div className="title">
                    <h1>Partnerships</h1>
                </div>

                <div className="wrapper">
                    <div className="item_list">
                        {img_list.map((d, k) => (
                            <div className="item" key={k}>
                                <img src={d} alt="" onLoad={onLoad} />
                            </div>
                        ))}
                        {img_list.map((d, k) => (
                            <div className="item" key={k}>
                                <img src={d} alt="" onLoad={onLoad} />
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}
