import {  useState } from "react";
import "./faq.scss"
import Expand from "react-expand-animated";

function Line(props) {

    const styles = {
        open: { width: "100%" },
        close: { width: "100%" }
    };
    const transitions = ["height", "opacity", "background"];

    return (
        <div className="line" onClick={props?.onClick}>
            <div className="question">
                <i className="show fas fa-plus" style={{ transform: `rotate(${props?.isExpand ? 45 : 0}deg)` }}></i>
                <p>{props?.question}</p>
            </div>
            <Expand
                open={props?.isExpand}
                duration={300}
                styles={styles}
                transitions={transitions}
            >
                {props?.children}
            </Expand>

        </div>
    )
}

export default function Faq() {



    const [faqId, setFaqId] = useState(-1);
    const handleClick = (id: number) => {
        if (faqId !== -1) {
            if (faqId === id) {
                setFaqId(-1)
            }
            else {
                setFaqId(id)
            }
        }
        else {
            setFaqId(id)
        }

    }
    return (
        <div className="faq" id="faq">
            <div className="faqContent">
                <div className="title">
                    <h4>FAQS</h4>
                    <h1>FAQs about BlowFish Tequila</h1>
                </div>
                <div className="wrapper" style={{ backgroundImage: `url("/assets/images/Grey-Background_Meaning_Heart.gif")` }}>

                    <Line
                        question={'Who can participate?'}
                        isExpand={faqId === 0}
                        onClick={() => { handleClick(0) }}
                        children={
                            <>
                                <p className="answer">○ The crowd-sale is available to U.S. accredited investors who are verified b</p>
                                <p className="answer">KYC/AML. Under Regulation D of the Securities Act, participation in the crowd-sale may be available to a limited number of non-accredited investors. For more information, please refer to Disclosures.</p>
                            </>}
                    />
                    <Line
                        question='Does the sale require a KYC?'
                        isExpand={faqId === 1}
                        onClick={() => { handleClick(1) }}
                        children={
                            <>
                                <p className="answer">○ Yes, participants in the crowd-sale must be verified by KYC/AML.</p>
                            </>}
                    />

                    <Line
                        question='What types of documents can I use to verify my identity or for KYC?'
                        isExpand={faqId === 2}
                        onClick={() => { handleClick(2) }}
                        children={
                            <>
                                <p className="answer">○ For U.S. individuals You may be required to upload a government issued photo ID that is currently valid and not expired, including:</p>
                                <p className="answer">● Driver's license ● Passport</p>
                            </>}
                    />
                    <Line
                        question='Why do you need a valid form of ID?'
                        isExpand={faqId === 3}
                        onClick={() => { handleClick(3) }}
                        children={
                            <>
                                <p className="answer">○ As a financial institution, just like your bank or broker, we need to be able to</p>
                                <p className="answer">verify each investor's identity. This is not just helpful for the startups we host (imagine trying to issue shares to someone who doesn't exist) it is also required under U.S. federal law to prevent terrorist financing and money laundering.</p>
                                <p className="answer">From time to time, you may be prompted to provide your SSN, ID or Passport (and if you are investing as an entity, relevant documents for your entity) to complete an investment. Which of these is asked for will depend on, and is specific, to your situation. We collect this data to verify who you are and to make sure you are not on a list that would prevent the startup from doing business with you.</p>
                                <p className="answer">When we collect your personal identifying information, it's sent through a secure link to an established third party verification service, we do not retain it. If your documents and numbers are cleared, there will be no further action required, if something isn't right, we will reach out and help you fix it. </p>
                                <p className="answer">We want everyone to be able to invest, however, failure to provide requested documents or information will result in your investment commitment being canceled.</p>
                            </>}
                    />
                    <Line
                        question='What is an accredited investor?'
                        isExpand={faqId === 4}
                        onClick={() => { handleClick(4) }}
                        children={
                            <>
                                <p className="answer">○ An accredited investor is an individual or a business entity that is allowed to trade</p>
                                <p className="answer">securities that may not be registered with financial authorities. They are entitled to this privileged access by satisfying at least one requirement regarding their income, net worth, asset size, governance status, or professional experience.</p>
                                <p className="answer">The regulations for accredited investors vary from one jurisdiction to the other and are often defined by a local market regulator or a competent authority. In the U.S, the definition of an accredited investor is put forth by SEC in Rule 501 of Regulation D. Electronic Code of Federal Regulations. "§230.501 Definitions and Terms Used in Regulation D."</p>
                                <p className="answer">To be an accredited investor, a person must have an annual income exceeding $200,000 ($300,000 for joint income) for the last two years with the expectation of earning the same or a higher income in the current year. An individual must have earned income above the thresholds either alone or with a spouse over the last two years. The income test cannot be satisfied by showing one year of an individual's income and the next two years of joint income with a spouse.</p>
                                <p className="answer">A person is also considered an accredited investor if they have a net worth exceeding $1 million, either individually or jointly with their spouse. This amount cannot include a primary residence. The SEC also considers a person to be an accredited investor if they are a general partner, executive officer, or director for the company that is issuing the unregistered securities.</p>
                                <p className="answer">An entity is considered an accredited investor if it is a private business development company or an organization with assets exceeding $5 million. Also, if an entity consists of equity owners who are accredited investors, the entity itself is an accredited investor.</p>
                                <p className="answer">However, an organization cannot be formed with the sole purpose of purchasing specific securities.</p>
                            </>}
                    />

                    <Line
                        question='Can I cancel my investment commitment?'
                        isExpand={faqId === 5}
                        onClick={() => { handleClick(5) }}
                        children={
                            <>
                                <p className="answer">○ No, commitments are non-revocable.</p>

                            </>}
                    />
                    <Line
                        question='Can I sell the securities I acquire?'
                        isExpand={faqId === 6}
                        onClick={() => { handleClick(6) }}
                        children={
                            <>
                                <p className="answer">○ According to federal law under Reg CF, you are restricted from reselling your securities in the first 12 months post-closing of the campaign, with a few exceptions:</p>
                                <ul>
                                    <li>to the company that issued the securities;</li>
                                    <li>to an accredited investor;</li>
                                    <li>to a nuclear family member</li>
                                </ul>
                                <p className="answer">● child</p>
                                <p className="answer">● step-child</p>
                                <p className="answer">● grandchild</p>
                                <p className="answer">● parent</p>
                                <p className="answer">● step-parent</p>
                                <p className="answer">● grandparent</p>
                                <p className="answer">● spouse/spousal equivalent</p>
                                <p className="answer">● sibling</p>
                                <p className="answer">● mother-in-law</p>
                                <p className="answer">● father-in-law</p>
                                <p className="answer">● son/daughter/brother/sister-in-law</p>
                                <ul>
                                    <li>in connection with your death, divorce, or other similar circumstance;</li>
                                    <li>to a trust controlled by you or a trust created for the benefit of a family</li>
                                    <li>member (defined as a child, sibling or parent of you or your spouse); or</li>
                                    <li>as part of a later offering registered with the SEC.</li>
                                </ul>
                                <p className="answer">○ After 12 months. The selling or transfer is allowed by U.S. federal law, but may still be subject to state or foreign laws. Always consult an attorney before transferring private-company securities.</p>
                                <p className="answer">○ No market. In most instances, the startup you’ve invested in is a private company, meaning they are not traded on a public market and cannot be easily sold. It is not guaranteed that a market–and therefore buyer–will become available, even after the initial 12-month period.</p>
                                <p className="answer">○ What qualifies as transfer of securities? Transferring means selling, gifting, or pledging — promising, using
                                    as collateral, selling the rights to but delivering at a later date, etc. In other words any way you could try to give away the economic rights without delivering it to someone.</p>
                                <p className="answer">○ Risk note: It is important that you only invest with the expectation of holding your investment for an indefinite period of time and with the real risk of a total loss of your investment. Only invest an amount you can afford to lose without changing your lifestyle.</p>
                            </>}
                    />

                    <Line
                        question='What are Membership Units?'
                        isExpand={faqId === 7}
                        onClick={() => { handleClick(7) }}
                        children={
                            <>
                                <p className="answer">○ A membership interest represents an investor's ownership stake in an Corp. Each investor in an Corp is called a “member.” A person who holds a membership interest has a profit and voting interest in the Corp. (although these may be amended by contract). Ownership in an Corp. can be expressed by percentage ownership interest or membership units.</p>
                                <p className="answer">○ An Corp. is different from a corporation in that it is able to distribute its membership interests in any way it wishes, regardless of the amount of capital contribution a member makes to the company. In addition, an Corp. can have different classes of membership interests, which enables it to allocate profits and voting rights in a special manner. </p>
                                <p className="answer">○ A membership interest is frequently represented in the admission of the investor's name to the Corp. to a ledger, this means no certificated security instruments may be issued to membership interest holders aka members.</p>
                                <p className="answer">○ If an Corp. is profitable, the Manager (equivalent to CEO) may make distributions to members on a pro-rated or other basis. Therefore like holding stock entitled to dividends, holding membership interests may entitle a holder to distributions.</p>
                                <p className="answer">○ If an Corp. is sold or merges, the members will receive cash or other assets as consideration for their ownership right.</p>

                            </>}
                    />

                    <Line
                        question='How do I track my investment?'
                        isExpand={faqId === 8}
                        onClick={() => { handleClick(8) }}
                        children={
                            <>
                                <p className="answer">○ All investors will have access to a personal portfolio dashboard on the Blowfish</p>
                                <p className="answer">Tequila trading platform. When the user connects a wallet to the platform, they will easily be able to navigate and access all the information about their digital share, plus information on other shares available for share, rewards earned, and other ways to participate on the platform.</p>

                            </>}
                    />

                    <Line
                        question='What perks do I receive for investing?'
                        isExpand={faqId === 9}
                        onClick={() => { handleClick(9) }}
                        children={
                            <>
                                <p className="answer">○ Diamond Rewards $50k+</p>
                                <ul>
                                    <li>10% Share Bonus (1 share per 10k)</li>
                                    <li>5+ Bottles Extra Añejo (1 bottle per 10k)</li>
                                    <li>45+ Bottle Credit (1 per share) Blanco/Reposado/Añejo-Credit</li>
                                    <li>Free Shipping (6 per Case) ok to mix/match</li>
                                </ul>
                                <p className="answer">○ Platinum Rewards $25k+</p>
                                <ul>
                                    <li>10% Share Bonus (1 share per 10k)</li>
                                    <li>2+ Bottles Extra Añejo (1 bottle per 10k)</li>
                                    <li>23+ Bottle Credit (1 per share)</li>
                                    <li>Blanco/Reposado/Añejo-Credit</li>
                                    <li>Free Shipping (6 per Case) ok to mix/match</li>
                                </ul>
                                <p className="answer">○ Gold Rewards $10k+</p>
                                <ul>
                                    <li>10% Share Bonus (1 share per 10k)</li>
                                    <li>1+ Bottle Extra Añejo (1 bottle per 10k)</li>
                                    <li>9+ Bottle Credit (1 per share)</li>
                                    <li>Blanco/Reposado/Añejo-Credit</li>
                                    <li>Free Shipping (6 per Case) ok to mix/match</li>
                                </ul>
                                <p className="answer">○ Silver Rewards $5k+</p>
                                <ul>
                                    <li>5+ Bottle Credit(1 per share)</li>
                                    <li>Blanco/Reposado/Añejo-Credit</li>
                                    <li>Free Shipping ok to mix/match</li>
                                </ul>
                            </>}
                    />

                </div>
            </div>
        </div>
    )
}
