import { useEffect, useState } from 'react';
import './home.scss'


type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};

export default function Home({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 2){
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    return (
        <>
        <div className="home" id = "home">
            <div className="homeContent">
                <div className="homeWrapper">
                    <div className="homeLeft">
                        <h1>Love Inspired!</h1>
                        <div className="btn_div">
                            <p>Join our community</p>
                        <div className="socialLinks">
                                
                                <a href="https://www.facebook.com/profile.php?id=61554290413112" target="_blank"rel="noreferrer">
                                    <i className="fab fa-facebook"></i>
                                </a> 
                                <a href="https://www.instagram.com/blowfishtequila/" target="_blank"rel="noreferrer">
                                <i className="fab fa-instagram-square"></i>
                                </a> 
                                <a href="https://twitter.com/blowfishtequila" target="_blank"rel="noreferrer">
                                    <i className="fab fa-twitter"></i>
                                </a> 
                                <a href="https://t.me/+EP8EAgiujA9jYjAx" target="_blank"rel="noreferrer">
                                    <i className="fab fa-telegram"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="homeRight">
                    <img src="/assets/images/bottle-group-psd.png" alt="" className="" onLoad={onLoad}/>
                    
                    </div>
                    
                    
                </div>
            </div>
            <img src="/assets/images/banner-beach-3-1.png" alt="" className="effect1" onLoad={onLoad}/>
        </div>

        

        </>
        
    )
}
