import { useEffect, useState } from "react";
import "./rewards.scss"
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { getUserBalance, scClaimRewards, scGetDistributeDetails } from "utils/contracts";
import { DistributeDetails, UserBalance } from "utils/typs";
import toast from "react-hot-toast";
import { useLoader } from "contexts/LoadingProvider";
import Counter from "components/Counter/Counter";
import RequestTable from "components/Tables/RequestTable";
import { CreditRequest, apiPostGetCreditRequests, apiPostRemoveCreditRequest, apiPostSubmitCreditRequest } from "utils/api";
import { ethers } from "ethers";

type PropsType = {
    setIsLoading?: (flag: boolean) => void;
};


export default function Rewards({ setIsLoading }: PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)

    }
    useEffect(() => {
        if (imgCount >= 1) {
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    const { loginStatus, chainId, library, account } = useActiveWeb3();
    const [blancoValue, setBlancoValue] = useState(0)
    const [reposadoValue, setReposadoValue] = useState(0)
    const [anejoValue, setAnejoValue] = useState(0)
    const [extraAnejoBlancoValue, setExtraAnejoBlancoValue] = useState(0)

    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')


    const [userBalance, setUserBalance] = useState<UserBalance>(null);
    const [distributedDetails, setDistributedDetails] = useState<DistributeDetails>(null);

    const [signedGetData, setSignedGetData] = useState('');
    const [creditRequests, setCreditRequests] = useState<CreditRequest[]>([]);

    useEffect(() => {
        const getSignMessage = async () => {
            try {
                const _signedData = await library.signMessage(ethers.utils.arrayify(ethers.utils.hashMessage(`${account}-BlowFish`)));
                const _creditRequests: CreditRequest[] = await apiPostGetCreditRequests(account, _signedData);
                setSignedGetData(_signedData);
                setCreditRequests(_creditRequests);
            } catch (error) {
                console.log(error);
                console.log("Signature Request Declined!");
            }
        }

        if (loginStatus && library) {
            getSignMessage();
        }

    }, [account, loginStatus, library]);

    useEffect(() => {
        if (loginStatus) {
            getUserBalance(account).then(
                (balance) => {
                    setUserBalance(balance);
                }
            );

            scGetDistributeDetails(account).then((distributedInfo) => {
                setDistributedDetails(distributedInfo);
            });
        }
    }, [account, loginStatus]);
    const [setLoading] = useLoader();

    const onClaim = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }
        setLoading(true)
        const load_toast_id = toast.loading("Reward is claiming now...");
        try {
            const bResult = await scClaimRewards(chainId, library);
            if (bResult) {
                toast.success("Claiming succeed!");
                scGetDistributeDetails(account).then((distributedInfo) => {
                    setDistributedDetails(distributedInfo);
                });
            } else {
                toast.error("Failed!");
            }
        } catch (error) {
            toast.error("Failed!");
        }
        toast.dismiss(load_toast_id);
        setLoading(false)
    }

    const onSubmit = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        setLoading(true)
        const load_toast_id = toast.loading("Credit request is submitting now...");
        try {
            const timestamp = Math.floor(Date.now() / 1000);
            const signedData = await library.signMessage(ethers.utils.arrayify(ethers.utils.hashMessage(`${account}-${timestamp}`)));
            const reply = await apiPostSubmitCreditRequest(
                account,
                timestamp,
                signedData,
                email,
                name,
                address1,
                address2,
                phoneNumber,
                blancoValue,
                reposadoValue,
                anejoValue,
                extraAnejoBlancoValue
            );
            if (reply) {
                toast.success("Submit Succeed!");
                const _creditRequests: CreditRequest[] = await apiPostGetCreditRequests(account, signedGetData);
                setCreditRequests(_creditRequests);
            } else {
                toast.error("Failed!");
            }
        } catch (error) {
            toast.error("Failed!");
        }
        toast.dismiss(load_toast_id);
        setLoading(false)
    }

    const onCancelRequest = async (id: number) => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        setLoading(true)
        const load_toast_id = toast.loading("Credit request is removing now...");
        try {
            const timestamp = Math.floor(Date.now() / 1000);
            const signedData = await library.signMessage(ethers.utils.arrayify(ethers.utils.hashMessage(`${account}-${timestamp}`)));
            const reply = await apiPostRemoveCreditRequest(
                account,
                timestamp,
                signedData,
                id
            );
            if (reply) {
                toast.success("Remove Succeed!");
                const _creditRequests: CreditRequest[] = await apiPostGetCreditRequests(account, signedGetData);
                setCreditRequests(_creditRequests);
            } else {
                toast.error("Failed!");
            }
        } catch (error) {
            toast.error("Failed!");
        }
        toast.dismiss(load_toast_id);
        setLoading(false)
    }


    return (
        <div className="rewards" id="rewards">
            <div className="content">
                <div className="title">
                    <h1>Rewards</h1>
                </div>

                <h3>Membership Bottle Request</h3>
                <p className="sub_text">Bottle Credit Regular : {distributedDetails?.membershipMintedCount}</p>
                <p className="sub_text mb_1">Bottle Credit Premium : {Math.trunc(distributedDetails?.membershipMintedCount / 10)}</p>

                <div className="wrapper" >
                    <div className="left">
                        <div className="row mb_1">
                            <h4>Membership Holdings : </h4>
                            <p>{(distributedDetails?.membershipTokenHoldingAmount || 0).toFixed(0)}</p>
                        </div>
                        <div className="row mb_1">
                            <h4>ETH Balance : </h4>
                            <p>{(userBalance?.ETHBalance || 0).toFixed(5)} ETH</p>
                        </div>
                        <div className="row mb_1">
                            <h4>USDT Balance : </h4>
                            <p>{(userBalance?.USDTBalance || 0).toFixed(3)} USDT</p>
                        </div>
                        <div className="row mb_1">
                            <h4>USDC Balance : </h4>
                            <p>{(userBalance?.USDCBalance || 0).toFixed(3)} USDC</p>
                        </div>
                        <div className="row mb_1">
                            <h4>Accrued Rewards : </h4>
                            <p>{(distributedDetails?.rewardsAmount || 0).toFixed(5)} ETH</p>
                        </div>
                        <div className="row mb_1">
                            <h4>Claimed Rewards : </h4>
                            <p>{(distributedDetails?.claimedRewardsAmount || 0).toFixed(5)} ETH</p>
                        </div>
                        <div className="btns">
                            <button className="button" onClick={onClaim}>CLAIM REWARDS</button>
                        </div>
                    </div>
                    {/* <div className="right">
                        
                        
                        <iframe src="https://us8.list-manage.com/contact-form?u=452a4c987e1fd5d9465a0f3e5&form_id=201b3b498c168ff45b03fabb4d215c78" frameBorder="0" width={'100%'} height={'100%'}></iframe>
                    </div> */}
                    <div className="right">
                        <div className="row">
                            <img src="/assets/images/blanco_750ml.png" alt="" className="bottle" />
                            <Counter value={blancoValue} setValue={setBlancoValue} />
                            <p className="big">Blanco 750ml</p>
                        </div>

                        <div className="row">
                            <img src="/assets/images/reposado_750ml.png" alt="" className="bottle" />
                            <Counter value={reposadoValue} setValue={setReposadoValue} />
                            <p className="big">Reposado 750ml</p>
                        </div>

                        <div className="row">
                            <img src="/assets/images/anejo_750ml.png" alt="" className="bottle" />
                            <Counter value={anejoValue} setValue={setAnejoValue} />
                            <p className="big">Anejo 750ml</p>
                        </div>

                        <div className="row">
                            <img src="/assets/images/extra_anejo_750ml.png" alt="" className="bottle" />
                            <Counter value={extraAnejoBlancoValue} setValue={setExtraAnejoBlancoValue} />
                            <p className="big">ExtraAnejo 750ml(Premium)</p>
                        </div>



                    </div>

                </div>
                <div className="wrapper">
                    <div className="col">
                        <div className="row column">
                            <h4>Total in Cart {blancoValue + reposadoValue + anejoValue + extraAnejoBlancoValue}</h4>
                            <div className="user_info">
                                <div className="line">
                                    <p>Email : </p>
                                    <input type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                </div>
                                <div className="line">
                                    <p>Name : </p>
                                    <input type="text" value={name} onChange={e => setName(e.target.value)} />
                                </div>
                                <div className="line">
                                    <p>Address 1 : </p>
                                    <input type="text" value={address1} onChange={e => setAddress1(e.target.value)} />
                                </div>
                                <div className="line">
                                    <p>Address 2 : </p>
                                    <input type="text" value={address2} onChange={e => setAddress2(e.target.value)} />
                                </div>
                                <div className="line">
                                    <p>Phone # </p>
                                    <input type="text" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="btns">
                            <button className="button" onClick={onSubmit}>SUBMIT</button>
                        </div>
                        <p className="sub_text">Note : Orders are shipped in cases of six(6)</p>
                        <p className="sub_text">If you have less 6 credits left we will ship the remaining bottles</p>
                    </div>
                </div>
                <div className="table_wrap">
                    <RequestTable data={creditRequests} hasButton onClick={onCancelRequest} />
                </div>
            </div>

            <img src="/assets/images/banner-beach-3-1.png" alt="" className="bg" onLoad={onLoad} />

        </div>
    )
}
