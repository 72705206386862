import './helpModal.scss'
import Bounce  from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
interface Props {
    showModal: boolean,
    setShowModal?: any

}
const HelpModal: React.FC<Props> = ({
    showModal,
    setShowModal
}) => {


    const [isStart, setIsStart] = useState(false)
    useEffect(() => {
        if(showModal)
        {
            
            setTimeout(() => {
                setIsStart(true)
            }, 100)
        }
        
    }, [setIsStart,  showModal]);
    const onClose = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowModal(false);
        }, 800)
    }
    return (
        <div className={showModal === true ? "helpModal active" : "helpModal"}>
            <Bounce opposite when={isStart}>
            <div className="modelContent">
                <div className="connectWalletHeader">
                    <button className="connectModalCloseButton" onClick={onClose}><i className="fas fa-times"></i></button>
                </div>
                <div className="connectWalletWrapper">
                    <p>Click <span>“Connect Wallet”</span></p>
                    <p>Select <span>Metamask</span> or <span>Wallet Connect</span></p>
                    <p>Approve the connection request from your wallet </p>
                    <p>Select <span>“Binance Smart Chain”</span> Network</p>
                    <p>Enter the amount of BNB, BUSD, and USDT you want to spend</p>
                    <p>Click <span>“Buy Now”</span></p>
                    <p>Approve the transaction on your wallet</p>
                    
                </div>
            </div>
            </Bounce>

        </div>
    )
}
export default HelpModal;