import { useEffect, useState } from 'react';
import './style.scss'
import toast from 'react-hot-toast';
import { getETHPrice, getUserBalance, scBuyWithETH, scBuyWithUSDC, scBuyWithUSDT, scGetPresaleDetails } from 'utils/contracts';
import { PresaleDetails, UserBalance } from 'utils/typs';

import { putCommas, truncateWalletString } from 'utils';
// import Button1 from 'components/Buttons/button1/Button1';
import MySelect from 'components/Buttons/MySelect';
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useLoader } from 'contexts/LoadingProvider';
import { mainnet } from 'wagmi';
import Loader from 'components/loader/Loader';
import BtnTimer from 'components/timer/BtnTimer';
import { mountWertWidget } from 'utils/mountWertWidget';

type PropsType = {
    setShowConnectModal?: (flag: boolean) => void;
    setShowBuyModal?: (flag: boolean) => void;
    setShowAccountModal?: (flag: boolean) => void;
    setShowHelpModal?: (flag: boolean) => void;
};
export default function ICOSection({ setShowAccountModal, setShowBuyModal, setShowHelpModal }: PropsType) {
    const { loginStatus, chainId, library, account, isConnected, switchNetwork } = useActiveWeb3();
    const { openConnectModal } = useConnectModal();

    useEffect(() => {
        if (loginStatus) {
            getUserBalance(account).then(
                (balance) => {
                    setUserBalance(balance);
                }
            );
        }
    }, [account, loginStatus]);

    const onConnectWallet = async () => {
        if (!isConnected) {
            openConnectModal();
        } else {
            switchNetwork(mainnet.id);
        }
    }

    const [presaleInfo, setPresaleInfo] = useState<PresaleDetails>(null);
    const [isLoadingApi, setIsLoadingApi] = useState(true);
    const [ethPrice, setETHPrice] = useState(0.00);
    const [userBalance, setUserBalance] = useState<UserBalance>(null);

    useEffect(() => {
        getETHPrice().then((price) => {
            setETHPrice(price);
        });

        scGetPresaleDetails().then((engineInfo) => {
            setPresaleInfo(engineInfo);
            setIsLoadingApi(false);
        });
    }, [])

    const [coinID, setCoinID] = useState(0)
    const onClickCoinBtn = (id: number) => {
        setCoinID(id)
    }
    // const onClickCard = () => {
    //     setCoinID(3)
    //     // setShowCardModal(true)
    // }

    const [payAmount, setPayAmount] = useState<number>(0.00)
    const [payAmountStr, setPayAmountStr] = useState('')
    const [receiveAmount, setReceiveAmount] = useState<number>(0)
    const [receiveAmountStr, setReceiveAmountStr] = useState('')
    const [maxVal, /*setMaxVal*/] = useState<number>(0.00)
    const [isPayFocused, setIsPayFocused] = useState(false)

    useEffect(() => {
        if (presaleInfo?.membershipTokenPriceInUSDT !== 0) {
            if (coinID === 0 && ethPrice !== 0) {
                setReceiveAmount(payAmount * ethPrice / presaleInfo?.membershipTokenPriceInUSDT || 0)
            } else {
                setReceiveAmount(payAmount / presaleInfo?.membershipTokenPriceInUSDT || 0)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coinID]);

    const rankingOption = [
        { label: <span className='coin_label'><img src="/assets/icons/icon_eth.svg" alt="" /> ETH</span>, value: 0 },
        { label: <span className='coin_label'><img src="/assets/icons/icon_usdt.svg" alt="" /> USDT</span>, value: 1 },
        { label: <span className='coin_label'><img src="/assets/icons/icon_usdc.svg" alt="" /> USDC</span>, value: 2 },
    ]

    const onClickConnectBtn = () => {
        if (loginStatus) {
            onBuy();
        } else {
            onConnectWallet();
        }
    }
    const onClickDisConnectBtn = () => {
        setShowAccountModal(true);
    }
    const onChangePayAmount = (e: any) => {
        setPayAmount(parseFloat(e.target.value));
        setPayAmountStr(e.target.value)
        if (ethPrice && presaleInfo?.membershipTokenPriceInUSDT) {
            if (coinID === 0 && ethPrice !== 0) {
                setReceiveAmount(parseFloat(e.target.value) * ethPrice / presaleInfo?.membershipTokenPriceInUSDT);
            } else {
                setReceiveAmount(parseFloat(e.target.value) / presaleInfo?.membershipTokenPriceInUSDT);
            }
        }
        setIsPayFocused(false)
    }
    const onChangeReceiveAmount = (e: any) => {
        setReceiveAmount(parseFloat(e.target.value))
        setReceiveAmountStr(e.target.value)
        if (coinID === 0 && ethPrice !== 0) {
            setPayAmount(parseFloat(e.target.value) * presaleInfo?.membershipTokenPriceInUSDT / ethPrice);
        } else {
            setPayAmount(parseFloat(e.target.value) * presaleInfo?.membershipTokenPriceInUSDT);
        }

        setIsPayFocused(true)
    }
    const onClickPayAmount = (e: any) => {

        setPayAmount(parseFloat(e.target.value));
        setPayAmountStr(e.target.value)
        setIsPayFocused(false)
    }
    const onClickReceiveAmount = (e: any) => {
        setReceiveAmount(parseFloat(e.target.value))
        setReceiveAmountStr(e.target.value)
        setIsPayFocused(true)
    }

    // const onChangeMax = () => {
    //     // if(!loginStatus) {
    //     //     toast.error("Please connect wallet correctly!");
    //     //     return;
    //     // }
    //     if (coinID === 0) {
    //         setMaxVal(userBalance?.ETHBalance || 0.00)
    //     }
    //     if (coinID === 1) {
    //         setMaxVal(userBalance?.USDCBalance || 0.00)
    //     }
    //     if (coinID === 2) {
    //         setMaxVal(userBalance?.USDTBalance || 0.00)
    //     }
    // }
    useEffect(() => {
        if (presaleInfo?.membershipTokenPriceInUSDT !== 0 && userBalance) {
            if (coinID === 0 && ethPrice !== 0) {
                setReceiveAmount(maxVal * ethPrice / presaleInfo?.membershipTokenPriceInUSDT || 0)

            } else {
                setReceiveAmount(maxVal / presaleInfo?.membershipTokenPriceInUSDT || 0)
            }
            setPayAmount(maxVal);
            setPayAmountStr(maxVal.toFixed(3))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coinID, maxVal, userBalance]);

    const onBuyNowHander = async () => {
        if (!loginStatus) {
            await onClickConnectBtn();
        } else {
            await onBuy();
        }
    };
    const [setLoading] = useLoader();
    const onBuy = async () => {
        if (!loginStatus) {
            toast.error("Please connect wallet correctly!");
            return;
        }

        // if (presaleInfo?.saleStep === 0) {
        //     toast.error("Sale is Not Open Yet. Please Wait Until Launch Day.");
        //     return;
        // }

        // if (receiveAmount < 1) {
        //     toast.error("Membership count must be higher than 1");
        //     return;
        // }

        if (coinID === 0) {
            setLoading(true)
            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithETH(chainId, library, account, payAmount, presaleInfo.saleStep);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetPresaleDetails().then((engineInfo) => {
                        setPresaleInfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
            setLoading(false)
        }
        else if (coinID === 1) {
            setLoading(true)
            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithUSDT(chainId, library, account, payAmount, presaleInfo.saleStep);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetPresaleDetails().then((engineInfo) => {
                        setPresaleInfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
            setLoading(false)
        }
        else if (coinID === 2) {
            setLoading(true)
            const load_toast_id = toast.loading("Payment is processing now...");
            try {
                const bResult = await scBuyWithUSDC(chainId, library, account, payAmount, presaleInfo.saleStep);
                if (bResult) {
                    toast.success("Payment succeed!");
                    scGetPresaleDetails().then((engineInfo) => {
                        setPresaleInfo(engineInfo);
                    });
                } else {
                    toast.error("Failed!");
                }
            } catch (error) {
                toast.error("Failed!");
            }
            toast.dismiss(load_toast_id);
            setLoading(false)
        }
        else if (coinID === 3) {
            setShowBuyModal(true)
            try {
                const ethAmount = payAmount / ethPrice * 1.05;
                await mountWertWidget(account, ethAmount);
            } catch (error) {
                console.log(error);
                toast.error("Failed!");
                setLoading(false)
            }
        }
    }
    // const onClaim = async () => {
    //     if (!loginStatus) {
    //         toast.error("Please connect wallet correctly!");
    //         return;
    //     }

    //     const load_toast_id = toast.loading("Token is claiming now...");
    //     try {
    //         const bResult = await scClaimRewards(chainId, library);
    //         if (bResult) {
    //             toast.success("Claiming succeed!");
    //             scGetPresaleDetails().then((engineInfo) => {
    //                 setPresaleInfo(engineInfo);
    //             });
    //         } else {
    //             toast.error("Failed!");
    //         }
    //     } catch (error) {
    //         toast.error("Failed!");
    //     }
    //     toast.dismiss(load_toast_id);
    // }

    return (
        <>
            <div className="ico_section" id='ico_section'>
                <div className="wrapper">
                    <div className="card">

                        <div className="card_content">
                            <h2>Private Offering Sale</h2>
                            <h3>{isLoadingApi ? ' ... ... ... ... ... ... ... ' : `1 Ownership Share = $${putCommas(presaleInfo?.membershipTokenPriceInUSDT || 0)}`}</h3>

                            {
                                presaleInfo?.saleStep === 0 ?
                                    <h3 className="usd_raised">Private Sale Opens Soon</h3> :
                                    <h3 className="usd_raised">USD Raised : <span>${putCommas(presaleInfo?.membershipTokenPriceInUSDT * presaleInfo?.membershipTokenSoldCount + 166000 || 0)}/${putCommas(750000)}</span></h3>
                            }

                            <BtnTimer deadLine={1712836800} />
                            {/* <div className="progress-div">
                                <div className="progress" style={{ width: `70%` }}></div>
                                <p>Until Next Price $1000 USD</p>
                            </div> */}
                            <div className="col_div">
                                <div className="row">
                                    <div className="btns">
                                        <MySelect
                                            value={coinID}
                                            options={rankingOption}
                                            onChange={onClickCoinBtn}
                                            className='filter_select'
                                        />
                                        {/* <button className={`button ${coinID === 3 ? 'outline':'' }`} onClick={() => { onClickCard() }}>
                                            <img src="/assets/icons/icon_card.svg" alt="" /> Credit Card
                                        </button> */}
                                    </div>
                                </div>

                                <div className="row">
                                    <h4>Membership Count You Receive:</h4>
                                    <div className="input_div">
                                        {!isPayFocused ?
                                            <input type="number"
                                                value={Math.trunc(receiveAmount)}
                                                onChange={onChangeReceiveAmount} inputMode='decimal' lang="en" onClick={onClickReceiveAmount} /> :
                                            <input type="text"
                                                value={receiveAmountStr}
                                                onChange={onChangeReceiveAmount} inputMode='decimal' lang="en" onClick={onClickReceiveAmount} />
                                        }
                                        <div className="img_div">
                                            <img src="/assets/logo.png" alt="" className='' style={{ borderRadius: 0 }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <h4>{coinID === 0 ? 'ETH' : coinID === 1 ? 'USDT' : coinID === 2 ? 'USDC' : 'USD'} Amount You Pay</h4>
                                    <div className="input_div">
                                        {isPayFocused ?
                                            <input type="number" value={payAmount.toFixed(3)} onChange={onChangePayAmount} inputMode='decimal' lang="en" step="any" onClick={onClickPayAmount} /> :
                                            <input type="text" value={payAmountStr} onChange={onChangePayAmount} onClick={onClickPayAmount} inputMode='decimal' lang="en" step="any" />
                                        }
                                        <div className="img_div">
                                            {coinID === 0 ? <img src="/assets/icons/icon_eth.svg" alt="" />
                                                : coinID === 1 ? <img src="/assets/icons/icon_usdt.svg" alt="" /> : coinID === 2 ? <img src="/assets/icons/icon_usdc.svg" alt="" /> : <img src="/assets/icons/icon_card.svg" alt="" style={{ borderRadius: 0 }} />}
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <button className="button full_w" onClick={() => { onBuyNowHander() }}>
                                        {loginStatus ? 'Buy Now' : 'Connect Wallet'}
                                    </button>
                                </div>
                                {loginStatus &&
                                    <div className="row">
                                        <button className="button full_w" onClick={onClickDisConnectBtn}>
                                            {truncateWalletString(account)} <i className="fas fa-sign-out-alt"></i>
                                        </button>
                                    </div>}
                            </div>

                            {isLoadingApi && <div className="loading_div">
                                <Loader />
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
