import { useEffect, useState } from 'react';
import './buy_sale.scss'
import ICOSection from 'components/ICOSection/ICOSection';
import AccountModal from 'components/Modals/accountModal/AccountModal';
import Modal from 'components/Modals/BuyModal/Modal';
import TokenModal from 'components/Modals/tokenModal/TokenModal';
import HelpModal from 'components/Modals/helpModal/HelpModal';
import CardModal from 'components/Modals/CardModal/CardModal';

type PropsType = {
    setIsLoading?: (flag: boolean) => void;
};
export default function BuySale({ setIsLoading }: PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)

    }
    useEffect(() => {
        if (imgCount >= 1) {
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);
    const [/*showConnectModal*/, setShowConnectModal] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const [showBuyModal, setShowBuyModal] = useState(false);
    const [showTokenModal, setShowTokenModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [showCardModal, setShowCardModal] = useState(false);


    return (
        <>
            <div className="buy_sale" id='buy_sale'>
                <div className="content" >

                    <div className="wrapper" data-aos="fade-up">
                        <div className="left_div" >
                            <h1> Private Offering Sale</h1>
                            {/* <p>We are a Tequila company here in the USA. We are looking to sell 33% of our company via
                                Digital ownerships/memberships. </p>
                            <p>Each share holder will receive prizes for 4 different tier levels as well as quarterly dividends of the companies profit. </p>
                            <p>Each Share holder will receive an ERC-1155 digital membership in their wallet. </p>
                            <p>This also allows them access to the members portal where they can get discounts on bottle and swag purchases. Users can claim their share rewards via ETH on a quarterly basis as well.</p> */}
                            <p className='mb_1'><strong>WhiteList Only (1 Share = $1,000USD)</strong></p>
                            <p className='mb_1'><strong>PO Share Available 750</strong></p>
                            <p className='mb_1'><strong>Total PO Raise $750,000</strong></p>
                            <p className='mb_1'><strong>Total PO Stake 10%</strong></p>
                            <p>Embark on exclusive journey through our private offering, where participation unlocks exceptional perks and rewards. As a cherished investor/member, relish in privileged portal access, special bottle discounts, exclusive event invites, and a complimentary bottle count tailored to your investment reward tier </p>
                            <p>(see tier levels below)</p>
                        </div>
                        <div className="right_div" >
                            <ICOSection
                                // setShowCardModal = {setShowCardModal} 
                                setShowConnectModal={setShowConnectModal}
                                setShowAccountModal={setShowAccountModal}
                                setShowBuyModal={setShowBuyModal}
                            />
                        </div>
                    </div>

                </div>
                <img src="/assets/images/Recipes_bk.jpg" alt="" className="effect" onLoad={onLoad} />
            </div>
            <AccountModal showAccountModal={showAccountModal} setShowAccountModal={setShowAccountModal} />
            <Modal
                showModal={showBuyModal}
                setShowModal={setShowBuyModal}
                children={
                    <>
                        <div id="wert-widget"></div>
                    </>
                }
            />

            <TokenModal showModal={showTokenModal} setShowModal={setShowTokenModal} />

            <HelpModal showModal={showHelpModal} setShowModal={setShowHelpModal} />
            <CardModal showModal={showCardModal} setShowModal={setShowCardModal} />
        </>
    )
}
