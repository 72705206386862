import './cardModal.scss'
import Bounce  from 'react-reveal/Bounce';
import { useEffect, useState } from 'react';
interface Props {
    showModal: boolean,
    setShowModal?: any

}
const CardModal: React.FC<Props> = ({
    showModal,
    setShowModal
}) => {

    const [isStart, setIsStart] = useState(false)
    useEffect(() => {
        if(showModal)
        {
            
            setTimeout(() => {
                setIsStart(true)
            }, 100)
        }
        
    }, [setIsStart,  showModal]);
    const onClose = ()=>{
        setIsStart(false)
        setTimeout(() => {
            setShowModal(false);
        }, 800)
    }
    return (
        <div className={showModal === true ? "cardModal active" : "cardModal"}>
            <Bounce opposite when={isStart}>
            <div className="modelContent">
                <div className="connectWalletHeader">
                    <button className="connectModalCloseButton" onClick={onClose}><i className="fas fa-times"></i></button>
                </div>
                <div className="connectWalletWrapper">
                    <p><span>“Coming Soon”</span></p>
                </div>
            </div>
            </Bounce>

        </div>
    )
}
export default CardModal;