import './style.scss'
import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { CreditRequest } from 'utils/api';


type Pros = {
  data?: CreditRequest[],
  hasButton?: boolean,
  onClick?: any
}


type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof CreditRequest;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'requestId',
    numeric: true,
    disablePadding: true,
    label: 'Request ID',
  },
  {
    id: 'walletAddress',
    numeric: true,
    disablePadding: true,
    label: 'Wallet',
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: true,
    label: 'Email',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: true,
    label: 'Name',
  },
  {
    id: 'address1',
    numeric: true,
    disablePadding: true,
    label: 'Address 1',
  },
  {
    id: 'address2',
    numeric: true,
    disablePadding: true,
    label: 'Address 2',
  },
  {
    id: 'phoneNumber',
    numeric: true,
    disablePadding: true,
    label: 'Phone',
  },
  {
    id: 'blancoCount',
    numeric: true,
    disablePadding: true,
    label: 'Blanco',
  },
  {
    id: 'reposadoCount',
    numeric: true,
    disablePadding: true,
    label: 'Reposado',
  },
  {
    id: 'anejoCount',
    numeric: true,
    disablePadding: true,
    label: 'Anejo',
  },
  {
    id: 'extraAnejoCount',
    numeric: true,
    disablePadding: true,
    label: 'Extra Anejo',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof CreditRequest) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  hasButton: boolean
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, hasButton } = props;
  const createSortHandler =
    (property: keyof CreditRequest) => (event: React.MouseEvent<unknown>) => {

      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>

        ))}
        {hasButton &&
          <TableCell
            padding={'none'}
          >
          </TableCell>}
      </TableRow>
    </TableHead>
  );
}

export default function RequestTable({ data, hasButton, onClick }: Pros) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof CreditRequest>('requestId');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CreditRequest,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    console.log(isAsc, property)
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n?.requestId.toString());
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, data],
  );
  return (
    <div className="my_table">
      <div className="tableContent">
        {(data === undefined) ?
          <div className='noData'>
            <span>No Data</span>
          </div> :

          <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
              {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader aria-label="sticky table"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                    hasButton={hasButton}
                  />
                  {(data.length !== 0) ?
                    <TableBody>
                      {visibleRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.requestId?.toString());
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) => { }}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={index}
                              selected={isItemSelected}

                            >
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                                align="left"
                                sx={{ width: 100 }}
                              >
                                <div className="myCell" style={{ justifyContent: 'left' }}>
                                  <p>{row.requestId}</p>
                                </div>
                              </TableCell>
                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.walletAddress}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.email}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.name}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell" style={{ maxWidth: 180 }}>
                                  <p>{row.address1}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell" style={{ maxWidth: 180 }}>
                                  <p>{row.address2}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.phoneNumber}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.blancoCount}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.reposadoCount}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.anejoCount}</p>
                                </div>
                              </TableCell>

                              <TableCell align="right" >
                                <div className="myCell">
                                  <p>{row.extraAnejoCount}</p>
                                </div>
                              </TableCell>
                              {hasButton &&
                                <TableCell align="right" >
                                  <div className="myCell">
                                    <button className="button" onClick={() => onClick(row.requestId)}>Cancel</button>
                                  </div>
                                </TableCell>
                              }
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (33) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody> :
                    <TableBody>
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          padding="none"
                          colSpan={10}
                        >
                          <div className="loadingCell">
                            <span>No Data</span>
                          </div>
                        </TableCell>
                      </TableRow>
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (33) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        }
      </div>
    </div>

  )
}