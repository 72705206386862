import { useEffect, useState } from 'react';
import './team.scss'

const team_data = [
    {
        title: 'Founder/CEO',
        name: 'Michael Bronw',
        img: '/assets/teams/1.png',
        link: ' https://www.linkedin.com/in/michael-brown-97480019/'
    },

    {
        title: 'COO/CTO',
        name: 'Adam Prangè',
        img: '/assets/teams/2.png',
        link: 'https://www.linkedin.com/in/adam-prange-171a83212/'
    },

    {
        title: 'CFO/Tequila Catadores',
        name: 'Robert Principe',
        img: '/assets/teams/3.png',
        link: ''
    },

    {
        title: 'Instant Logistics Amazon NCE',
        name: 'Robert Wegner - Adviser',
        img: '/assets/teams/4.png',
        link: 'https://www.linkedin.com/in/robert-bert-wegner-8a66333/'
    },

    {
        title: 'Web3/Blockchain Guru',
        name: 'Kevan Williams',
        img: '/assets/teams/5.png',
        link: 'https://www.linkedin.com/in/kevan-williams-317a80a1/'
    },

    {
        title: 'Adviser/Wealth Protection, Sage Int',
        name: 'Cheri Hill',
        img: '/assets/teams/6.png',
        link: 'https://www.linkedin.com/in/cherihill/'
    },

    {
        title: 'Marketing/Media',
        name: 'Danielle Dino',
        img: '/assets/teams/7.png',
        link: 'https://www.linkedin.com/in/webcasa/'
    },

    {
        title: 'Web3/Blockchain Development',
        name: 'Erdene Bilege',
        img: '/assets/teams/8.png',
        link: 'https://www.linkedin.com/in/erdene-bilege-4bb8b2253/'
    },

    {
        title: 'Full Stack Development',
        name: 'Muhammad Anas',
        img: '/assets/teams/9.png',
        link: ''
    },

    {
        title: 'Web3/Graphics Blockchain Dev',
        name: 'Abdul Razaq',
        img: '/assets/teams/10.png',
        link: ''
    },

]
type PropsType = {
    setIsLoading?: (flag: boolean) => void;
};
export default function Team({ setIsLoading }: PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)

    }
    useEffect(() => {
        if (imgCount >= 4) {
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);



    return (
        <div className="team" id='team'>
            <div className="content">
                <div className="title">
                    <h1>Our Team</h1>
                </div>

                <div className="wrapper">
                    <div className="team_list">
                        {team_data.map((d, k) => (
                            d?.link ?
                                <a href={d.link} className='item' key={k} target="_blank"rel="noreferrer">
                                    <img src={d.img} alt="" className="avatar" onLoad={onLoad} />
                                    <h2>{d.name}</h2>
                                    <p>{d.title}</p>
                                    <div className='link'><i className="fab fa-linkedin"></i></div>
                                </a> :
                                <div className='item' key={k}>
                                    <img src={d.img} alt="" className="avatar" onLoad={onLoad} />
                                    <h2>{d.name}</h2>
                                    <p>{d.title}</p>
                                </div>
                        ))}
                       {team_data.map((d, k) => (
                            d?.link ?
                                <a href={d.link} className='item' key={k} target="_blank"rel="noreferrer">
                                    <img src={d.img} alt="" className="avatar" onLoad={onLoad} />
                                    <h2>{d.name}</h2>
                                    <p>{d.title}</p>
                                    <div className='link'><i className="fab fa-linkedin"></i></div>
                                </a> :
                                <div className='item' key={k}>
                                    <img src={d.img} alt="" className="avatar" onLoad={onLoad} />
                                    <h2>{d.name}</h2>
                                    <p>{d.title}</p>
                                </div>
                        ))}
                    </div>


                </div>
            </div>
        </div>
    )
}
