import Menu from 'components/Layout/menu/Menu';
import Topbar from 'components/Layout/topbar/Topbar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Footer from 'components/Layout/footer/Footer';
export default function PrivacyPolicyPage( ) {

    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 640px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 640px) and (orientation:landscape)",});
    useEffect(() => {

        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
        
       
    }, [isTabletOrMobile, isLandOrMobile]);

    return (
        <>
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <div className="sections" >
                <div className="help_div" style={{backgroundImage : `url("/assets/images/Grey-Background_Meaning_Heart.gif")`}}>
                    <h1 className='mb_64'>Privacy Policy</h1>

                    <h3>Who we are</h3>
                    <p className='mb_32'>Our website address is: https://blowfishtequila.com.</p>

                    <h3>Comments</h3>
                    <p className='mb_32'>Our website does not allow public commenting</p>

                    <h3>Cookies</h3>
                    <p className='mb_32'>Our website uses cookies to improve your experience while visiting our website.</p>

                    <h3>Embedded content from other websites</h3>
                    <p className='mb_32'>Some pageson this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.</p>
                    <p className='mb_32'>These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>

                    <h3>Who we share your data with</h3>
                    <p className='mb_32'>We do not share your data with any 3rd parties.  We only respond to contact form requests and purchases.</p>

                    <h3>How long we retain your data</h3>
                    <p className='mb_32'>For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.</p>

                    <h3>What rights you have over your data</h3>
                    <p className='mb_32'>If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.</p>

                    <p className='mb_32'>This privacy policy was updated on 8/1/2022. </p>

                </div>
                <Footer/>
            </div>
        </>
    )
}
