import { useEffect, useState } from 'react';
import './solidproof.scss'

type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};


export default function Solidproof({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
    }
    useEffect(() => {
        if(imgCount>=2){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [imgCount, setIsLoading]);



    return (
        <div className="solidproof" id = 'solidproof'>
            <div className="content">
                
                <div className="wrapper" >
                    <div className="left">
                    <img src="/assets/images/solid-proof.png" alt="" className="" onLoad={onLoad}/>
                    </div>
                    <div className="right">
                        <p><span>Solidproof audits</span> audits offer comprehensive assessments to ensure the security and reliability of blaockchain-based contracts. Their meticulous analysis helps businesses mitigate risks and build trust in their decentralized applications. Click here for the full audit report</p>
                        <p>Coming Soon...</p>

                    </div>
                </div>
                    
            </div>
            <img src="/assets/images/Grey-Background_Meaning_Heart.gif" alt="" className="effect" onLoad={onLoad}/>
        </div>
    )
}
