import { useEffect, useState } from 'react';
import './roadmap.scss'

const firstData = [
    {
        level : 'Q4 2023',
        dec : ['Total Wine Partnership', 'Keg & Bottle Partnership', 'Portal Beta Tested', 'White-paper Release', 'Pitch Deck Release', 'Web3 Extension', 'Increased Brand Awareness', 'Celebrate 2 years of Crafting Premium Tequila'],
        img : '/assets/images/Camaralenta-GettyImages-1.png'

    },
    {
        level : 'Q1 2024',
        dec : ['Whitelist Opens', 'Launch of Online Portal', 'Create Massive Target Market Ad Campaign', 'Strengthen Current Partnerships for Larger Distribution', 'Private Offering Goes Live', 'Host exclusive events and tastings'],
        img : '/assets/images/Camaralenta-GettyImages-2.png'

    },
    {
        level : 'Q2 2024',
        dec : ['Public Sale Goes Live w/ portal expansion', 'Expand distribution channels to new markets and retail partners', 'Increased Production revolving inventory increases to $500k', 'Foster a community of brand advocates', 'BlowFish Vegas Takeover'],
        img : '/assets/images/Camaralenta-GettyImages-3.png'
    },
    {
        level : 'Q3 2024',
        dec : ['Develop the ultrapremium tequila variant.', 'Explore international market opportunities', 'Host interactive events and digital campaigns', 'Increased Production revolving inventory increases to $1M', 'Southern W&S Partnership'],
        img : '/assets/images/Camaralenta-GettyImages-4.png'
    },
    {
        level : 'Q4 2024',
        dec : ['Launch of Blowfish Tequila Seltzer', 'Vegas Takeover #2', 'Secure Walmart Partnership', 'Liquor Barn, Crown Liquors and ABC Fine Wine & Spirits', 'Social Media to over 100k Followers', 'Marketing with Chilled Magazine, Tasting Panel Cigar & Spirits'],
        img : '/assets/images/Camaralenta-GettyImages-6.png'
    },
]
type PropsType = {
    setIsLoading?:(flag: boolean)=> void;
};
export default function RoadMap({setIsLoading}:PropsType) {

    const [imgCount, setImgCount] = useState(0)
    const onLoad = ()=>{
        setImgCount(imgCount + 1)
        
    }
    useEffect(() => {
        if (imgCount >= 15){
            setTimeout(() => {
                setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    return (
        <div className="roadmap" id="roadmap">
            <div className="title">
                <h4>Roadmap</h4>
                <h1>ROADMAP</h1>
            </div>

            <div className="roadmapContent" id='roadmap'>
                <div className="wrapper">
                {firstData.map((d,i) =>(
                    <div className="row" key = {i}>
                    {i % 2 === 0 ? 
                    <>
                        <div className="node left">
                            <div className="content">
                                <div className="level">{d.level}</div>
                                {d.dec.length !== 0 &&
                                <div className="desc">
                                    {d.dec.map((e, k)=>(
                                        <p key = {k}>- {e} {i === 0 && <i className="fas fa-check"></i>}</p>
                                    ))}
                                </div>}
                                <img src="/assets/images/concrete-wall-process-bk.jpg" alt="" className="bg" onLoad = {onLoad}/>
                                <div className="circle">
                                    <img src={d.img} alt="" onLoad = {onLoad}/>
                                </div>
                                <div className="circle1"></div>
                                <div className="circle2"></div>
                                <div className="circle3"></div>
                            </div>
                            
                            
                        </div>
                        <div className="node">
                            <img src="/assets/images/Fish.png" alt=""  className='fish'onLoad = {onLoad}/>
                        </div>
                    </>
                    :
                    <>
                        <div className="node">
                            <img src="/assets/images/Fish_02.png" alt="" className='fish mr_0'onLoad = {onLoad}/>
                        </div>
                        <div className="node right">
                            
                            <div className="content">
                            <div className="circle">
                                <img src={d.img} alt="" onLoad = {onLoad}/>
                            </div>
                            <div className="level">{d.level}</div>
                            {d.dec.length !== 0 &&
                                <div className="desc">
                                    {d.dec.map((e, k)=>(
                                        <p key = {k}>- {e} {i === 1  && <i className="fas fa-check"></i>}</p>
                                    ))}
                                </div>}
                                <img src="/assets/images/concrete-wall-process-bk.jpg" alt="" className="bg" onLoad = {onLoad}/>
                                <div className="circle1"></div>
                                <div className="circle2"></div>
                            </div>
                           
                        </div>
                    </>
                    }

                </div>
                ))}
                </div>
            </div>
        </div>
    )
}
