import Loading from 'components/loading/Loading';
import Menu from 'components/Layout/menu/Menu';
import Topbar from 'components/Layout/topbar/Topbar';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Footer from 'components/Layout/footer/Footer';
import CreditRequestSection from 'components/sections/CreditRequest/CreditRequestSection';
export default function CreditRequestPage( ) {
    const [isLoading, setIsLoading] = useState(true);

    const [isLoading1, setIsLoading1] = useState(true);

    const [menuOpen, setMenuOpen] = useState(false);
    const isTabletOrMobile = useMediaQuery({query: "screen and (max-width: 640px) and (orientation:portrait)",});
    const isLandOrMobile = useMediaQuery({query: "screen and (max-height: 640px) and (orientation:landscape)",});
    useEffect(() => {
        if (isLoading1) {
            setIsLoading(true)
        }
        else{
            setIsLoading(false)
        }
        if (!isLandOrMobile && !isTabletOrMobile) {
            setMenuOpen(false);
        }
       
    }, [isLoading1, isTabletOrMobile, isLandOrMobile]);

    return (
        <>
            <Topbar menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Menu menuOpen = {menuOpen} setMenuOpen = {setMenuOpen}/>
            <Loading isLoading={isLoading} />
            <div className="sections" style={{opacity : isLoading ? 0: 1, height : isLoading ? 0: 'auto'}}>
                <CreditRequestSection setIsLoading={setIsLoading1}/>
                <Footer/>
            </div>
        </>
    )
}
