import toast from "react-hot-toast";
import { baseApiUrl } from "utils";

export interface CreditRequest {
    requestId: number;
    walletAddress: string;
    email: string;
    name: string;
    address1: string;
    address2: string;
    phoneNumber: string;
    
    blancoCount: number;
    reposadoCount: number;
    anejoCount: number;
    extraAnejoCount: number;
}

export const apiPostGetTotalCreditRequests = async (walletAddress, signData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("walletAddress", walletAddress);
    urlencoded.append("signData", signData);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const response = await fetch(`${baseApiUrl}/credit/getTotalCreditRequest`, requestOptions);
    const responseData = await response.json();
    if (responseData.status === 'success') {
        const creditRequests: CreditRequest[] = responseData.creditRequests;
        return creditRequests;
    } else {
        toast.error(responseData.data.error);
    }
    return [];
}

export const apiPostGetCreditRequests = async (walletAddress, signData) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("walletAddress", walletAddress);
    urlencoded.append("signData", signData);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const response = await fetch(`${baseApiUrl}/credit/getCreditRequest`, requestOptions);
    const responseData = await response.json();
    if (responseData.status === 'success') {
        const creditRequests: CreditRequest[] = responseData.creditRequests;
        return creditRequests;
    } else {
        toast.error(responseData.data.error);
    }
    return [];
}

export const apiPostSubmitCreditRequest = async (walletAddress, timestamp, signData, email, name, address1, address2, phoneNumber, blancoCount, reposadoCount, anejoCount, extraAnejoCount) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("walletAddress", walletAddress);
    urlencoded.append("timestamp", timestamp);
    urlencoded.append("signData", signData);
    urlencoded.append("email", email);
    urlencoded.append("name", name);
    urlencoded.append("address1", address1);
    urlencoded.append("address2", address2);
    urlencoded.append("phoneNumber", phoneNumber);
    urlencoded.append("blancoCount", blancoCount);
    urlencoded.append("reposadoCount", reposadoCount);
    urlencoded.append("anejoCount", anejoCount);
    urlencoded.append("extraAnejoCount", extraAnejoCount);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const response = await fetch(`${baseApiUrl}/credit/submitCreditRequest`, requestOptions);
    const responseData = await response.json();
    if (responseData.status === 'success') {
        return true;
    } else {
        toast.error(responseData.data.error);
    }
    return false;
}

export const apiPostRemoveCreditRequest = async (walletAddress, timestamp, signData, requestId) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("walletAddress", walletAddress);
    urlencoded.append("timestamp", timestamp);
    urlencoded.append("signData", signData);
    urlencoded.append("requestId", requestId);

    const requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
    };

    const response = await fetch(`${baseApiUrl}/credit/removeCreditRequest`, requestOptions);
    const responseData = await response.json();
    if (responseData.status === 'success') {
        return true;
    } else {
        toast.error(responseData.data.error);
    }
    return false;
}