/* eslint-disable no-undef */
import WertWidget from '@wert-io/widget-initializer';
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer/';
import { ethers } from 'ethers';
import { getContractInfo, wertPartnerId, wertPrivateKey } from 'utils';
// import {utils, getPublicKeyAsync} from '@noble/ed25519';


window.Buffer = Buffer; // needed to use `signSmartContractData` in browser

/* We advise you not to use the private key on the frontend
    It is used only as an example
*/

export const mountWertWidget = async (userAddress: string, ethAmount: number) => {
  // const privateKey = utils.randomPrivateKey(); // 32-byte Uint8Array or string.
  // const publicKey = await getPublicKeyAsync(privateKey);

  // console.log(`Private key: 0x${Buffer.from(privateKey).toString('hex')}` );
  // console.log(`Public key: 0x${Buffer.from(publicKey).toString('hex')}` );

  // Get user address
  const contractInfo = getContractInfo('BlowFish', 11155111);
  const iFace = new ethers.utils.Interface(contractInfo.abi);
  const sc_input_data = iFace.encodeFunctionData("mintMembership_with_ETH_wert", [userAddress]);

  // console.log(userAddress);
  // console.log(parseFloat(ethAmount.toFixed(3)));
  // console.log(wertPartnerId);
  // console.log(wertPrivateKey);
  
  // Create signed SC data for wert-widget
  // Please do this on backend
  const signedData = signSmartContractData({
    address: userAddress, // user's address
    commodity: 'ETH',
    commodity_amount: parseFloat(ethAmount.toFixed(3)), // the crypto amount that should be sent to the contract method
    network: 'sepolia',
    sc_address: contractInfo.address, // your SC address
    // network: 'mumbai', // For Testnet 
    // sc_address: "0x81435F6659D2a25a90D8a567A04aA73B24db4B85", // your Testnet SC address
    sc_input_data: sc_input_data,
  }, wertPrivateKey);

  const otherWidgetOptions = {
    partner_id: wertPartnerId, // your partner id
    container_id: 'wert-widget',
    click_id: uuidv4(), // unique id of purhase in your system
    origin: 'https://sandbox.wert.io', // this option needed only in sandbox
    // origin: 'https://widget.wert.io', // this option needed only in production
    // commodities: [{ commodity: "ETH", network: "sepolia" }],
    theme: "white",
    lang: "en",
    // autosize: true,
    width: 400,
    height: 600
  };

  const wertWidget = new WertWidget({
    ...signedData,
    ...otherWidgetOptions
  });

  wertWidget.mount();
}
