import './app.scss';
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import HomePage from 'pages/HomePage';
import { Toaster } from 'react-hot-toast';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';
import ProfilePage from 'pages/ProfilePage';
import CreditRequestPage from 'pages/CreditRequestPage';
function App() {
  return (
    <>
      <Toaster
        position="top-center"
        toastOptions={{
          success: { duration: 3000 },
          error: { duration: 3000 },
          className : 'myToast'
        }}
        
      />
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/privacy" component={PrivacyPolicyPage} />
        <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/credit_request" component={CreditRequestPage} />
      </Switch>
    </Router>
    </>
  );
}

export default App;
