
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { createContext, useState, useContext } from 'react'


interface LoadingType{
  loading : boolean
  setLoading: any
}

const LoadingContext = createContext<LoadingType>({
  loading : false,
  setLoading: (val : boolean) => {}
  }
  );

export function LoadingProvider(props) {
  const [loading, setLoading] = useState(false)
  const value = { loading , setLoading }

  return (
    // @ts-ignore
    <LoadingContext.Provider value={value}>
      {props.children}
      <LoadingPage isLoading = {value.loading}  />
    </LoadingContext.Provider>
  )
}
export const useLoader = () => {
  const { setLoading } = useContext(LoadingContext)
  return [setLoading]
}