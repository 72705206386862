
import { useEffect, useState } from 'react';
import { truncateWalletString } from 'utils';
import AccountModal from "components/Modals/accountModal/AccountModal";
import './topbar.scss'
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { HashLink } from 'react-router-hash-link'
import { useLocation } from 'react-router-dom';
import { mainnet } from 'wagmi';

type MenuType = {
    menuOpen?: boolean;
    setMenuOpen(flag: boolean): void;
};
export default function Topbar({ menuOpen, setMenuOpen }: MenuType) {
    const [showAccountModal, setShowAccountModal] = useState(false);
    const { openConnectModal } = useConnectModal();

    const { loginStatus, account, isConnected, switchNetwork } = useActiveWeb3();


    const [navId, setNavId] = useState('')
    const search = useLocation();

    useEffect(() => {
        const label = search.hash.replace('#', '')
        setNavId(label)
    }, [setNavId, search]);

    const onConnectWallet = async () => {
        if (!isConnected) {
            openConnectModal();
        } else {
            switchNetwork(mainnet.id);
        }
    }

    return (
        <div className="topbar">
            <div className="topbar_content">

                <div className="logo_mob">
                    <HashLink to="/#" smooth>
                        <img src="/assets/logo.png" alt="" />
                    </HashLink>
                </div>
                <div className="left">
                    <ul className="nav_list">
                        <li>
                        <HashLink to="/#" smooth className={navId === '' ? 'active' : ''}>Home</HashLink>
                        </li>
                        <li>
                        <HashLink to="/#buy_sale" smooth className={navId === '' ? 'active' : ''}>Buy/Sale</HashLink>
                        </li>
                        <li>
                            <HashLink to="/#solidproof" smooth>SolidProof Audit</HashLink>
                        </li>
                        <li>
                            <HashLink to="/#team" smooth>Team</HashLink>
                        </li>
                    </ul>
                </div>
                <div className="center">
                    <div className="logo">
                        <HashLink to="/#" smooth>
                            <img src="/assets/image-2.png" alt="" />
                        </HashLink>
                    </div>
                </div>
                <div className="right">
                    <ul className="nav_list">
                        <li>
                            <HashLink to="/#roadmap" smooth>Roadmap</HashLink>
                        </li>
                        <li>
                            <a href="https://docsend.com/view/zptnu2teuqyep923" target="_blank" rel="noreferrer">
                                White paper
                            </a>
                        </li>
                        <li>
                            <a href="https://docsend.com/view/dk4inxihry6gmu5q" target="_blank" rel="noreferrer">
                                Pitchdeck
                            </a>
                        </li>
                        <li>

                        </li>
                    </ul>
                    <div className="btns">
                        {loginStatus &&
                            <HashLink to="/profile" smooth>
                                <img src="/assets/avatar.png" alt="" className='avatar' />
                            </HashLink>}
                        {/* {loginStatus && <div className="col">
                                    <div className="balance button">
                                        <img src="/assets/icons/bnb.png" alt="" />
                                        <p><span> {userBalance?.BNBBalance?.toFixed(3) || "0.000"} </span> BNB</p>
                                    </div>
                                    <div className="balance button">
                                        <img src="/assets/icons/icon_usdt.svg" alt="" />
                                        <p><span> {userBalance?.USDTBalance?.toFixed(3) || "0.000"} </span> USDT</p>
                                    </div>
                                    <div className="balance button">
                                        <img src="/assets/icons/icon_busd.webp" alt="" />
                                        <p><span> {userBalance?.BUSDBalance?.toFixed(3) || "0.000"} </span> BUSD</p>
                                    </div>
                                    </div>} */}

                        <div className="connectBtn button" onClick={() => { !loginStatus ? onConnectWallet() : setShowAccountModal(true); }}>
                            <p><img src="/assets/icons/icon_wallet.svg" alt="" /> {loginStatus ? truncateWalletString(account) : "Connect Wallet"}</p>
                        </div>
                    </div>
                </div>

            </div>


            <div className={(menuOpen ? "hamburger active" : "hamburger")} onClick={() => setMenuOpen(!menuOpen)}>
                <span className="line1"></span>
                <span className="line2"></span>
                <span className="line3"></span>
            </div>
            <AccountModal showAccountModal={showAccountModal} setShowAccountModal={setShowAccountModal} />
        </div>
    )
}
