import { useEffect, useState } from "react";
import "./creditRequest.scss"
import RequestTable from "components/Tables/RequestTable";
import { CreditRequest, apiPostGetTotalCreditRequests } from "utils/api";
import { useActiveWeb3 } from 'hooks/useActiveWeb3';
import { ethers } from "ethers";
type PropsType = {
    setIsLoading?: (flag: boolean) => void;
};

export default function CreditRequestSection({ setIsLoading }: PropsType) {
    const [imgCount, setImgCount] = useState(0)
    const onLoad = () => {
        setImgCount(imgCount + 1)

    }
    useEffect(() => {
        if (imgCount >= 1) {
            setTimeout(() => {
                setIsLoading && setIsLoading(false)
            }, 500);
        }
    }, [setIsLoading, imgCount]);

    const [creditRequests, setCreditRequests] = useState<CreditRequest[]>([]);

    const { loginStatus, library, account } = useActiveWeb3();
    useEffect(() => {
        const getSignMessage = async () => {
            try {
                const signedData = await library.signMessage(ethers.utils.arrayify(ethers.utils.hashMessage(`${account}-BlowFish`)));
                const _creditRequests: CreditRequest[] = await apiPostGetTotalCreditRequests(account, signedData);
                setCreditRequests(_creditRequests);
            } catch (error) {
                console.log(error);
                console.log("Signature Request Declined!");
            }
        }

        if (loginStatus && library) {
            getSignMessage();
        }

    }, [account, loginStatus, library]);


    return (
        <div className="creditRequest">
            <div className="content">
                <div className="title">
                    <h1>Total Credit Requests</h1>
                </div>

                <div className="wrapper" >
                    <RequestTable data={creditRequests} />
                </div>

            </div>
            <img src="/assets/images/Grey-Background_Meaning_Heart.gif" alt="" className="bg" onLoad={onLoad} />
        </div>
    )
}
